/* eslint-disable no-unused-vars */
// src/components/LandingPage.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    // Simulate loader timeout
    useEffect(() => {
        const timer = setTimeout(() => setIsLoading(false), 200); // Adjust if needed
        return () => clearTimeout(timer);
    }, []);

    const handleGetStarted = () => {
        navigate('/signin');
    };

    return (
        <div className="landing-page">
            {isLoading && (
                <div className="preloader" aria-busy="true" aria-label="Loading">
                    <div className="loader">
                        <div className="ytp-spinner">
                            <div className="ytp-spinner-container">
                                <div className="ytp-spinner-rotator">
                                    <div className="ytp-spinner-left">
                                        <div className="ytp-spinner-circle"></div>
                                    </div>
                                    <div className="ytp-spinner-right">
                                        <div className="ytp-spinner-circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {!isLoading && (
                <>
                    <header className="header navbar-area bg-white">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <nav className="navbar navbar-expand-lg" aria-label="Main Navigation">
                                        <a className="navbar-brand" href="/" aria-label="OceanOps AI Home">
                                            <img src="/assets/img/logo/logo.png" alt="OceanOps AI Logo" />
                                        </a>
                                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                            aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="toggler-icon"></span>
                                            <span className="toggler-icon"></span>
                                            <span className="toggler-icon"></span>
                                        </button>
                                        <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                            <ul id="nav" className="navbar-nav ml-auto">
                                                <li className="nav-item"><a className="page-scroll active" href="#home" aria-label="Navigate to Home Section">Home</a></li>
                                                <li className="nav-item"><a className="page-scroll" href="#about" aria-label="Navigate to About Section">About</a></li>
                                                <li className="nav-item"><a className="page-scroll" href="#services" aria-label="Navigate to Services Section">Services</a></li>
                                                <li className="nav-item"><a className="page-scroll" href="#portfolio" aria-label="Navigate to Portfolio Section">Portfolio</a></li>
                                                <li className="nav-item"><a className="page-scroll" href="#team" aria-label="Navigate to Team Section">Team</a></li>
                                                <li className="nav-item"><a className="page-scroll" href="#contact" aria-label="Navigate to Contact Section">Contact</a></li>
                                            </ul>
                                            <div className="header-btn">
                                                <button onClick={handleGetStarted} className="theme-btn">Get Started</button>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </header>

                    <main>
                        <section id="home" className="carousel-section-wrapper" aria-label="Welcome Section">
                            <div id="carouselExampleCaptions" className="carousel slide carousel-fade" data-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-section carousel-item active clip-bg pt-225 pb-200 img-bg" >
                                        <video autoPlay loop muted playsInline className="carousel-video" aria-label="Promotional Video">
                                            <source src="assets/img/carousel/cargovid2.mp4" type="video/mp4" />
                                        </video>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-xl-8 col-lg-10 mx-auto">
                                                    <div className="carousel-content text-center">
                                                        <div className="section-title">
                                                            <h1 className="text-white">Welcome to OceanOps</h1>
                                                            <p className="text-white">Efficient vessel traffic and berth management for smooth port operations.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* About Section */}
                        <section id="about" className="pt-100" aria-label="About OceanOps">
                            <div className="about-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6">
                                            <div className="about-img-wrapper">
                                                <div className="about-img position-relative d-inline-block wow fadeInLeft" data-wow-delay=".3s">
                                                    <img src="assets/img/about/about-img.png" alt="About OceanOps Illustration" />
                                                    <div className="about-experience">
                                                        <h3>Simplify Your Vessel Management</h3>
                                                        <p>Our platform streamlines berth allocation and traffic coordination, minimizing delays. Explore our user-friendly dashboard to track vessel schedules and port capacities along major shipping routes.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6">
                                            <div className="about-content-wrapper">
                                                <div className="section-title">
                                                    <span className="wow fadeInUp" data-wow-delay=".2s">About Us</span>
                                                    <h2 className="mb-40 wow fadeInRight" data-wow-delay=".4s">Innovating Maritime Vessel Management for Shipping Ports</h2>
                                                </div>
                                                <div className="about-content">
                                                    <p className="mb-45 wow fadeInUp" data-wow-delay=".6s">
                                                        At OceanOps, we leverage advanced analytics and AI to empower port authorities and shipping companies in optimizing berth allocation and streamlining vessel traffic in real time. Our team brings extensive experience in remote sensing and geoinformatics, paired with strong data science expertise, to transform complex maritime data into actionable insights.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Services Section */}
                        <section id="services" className="service-section pt-130" aria-label="Our Services">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-7 col-md-9 mx-auto">
                                        <div className="section-title text-center mb-55">
                                            <span className="wow fadeInDown" data-wow-delay=".2s">Services</span>
                                            <h2 className="wow fadeInUp" data-wow-delay=".4s">At OceanOps</h2>
                                            <p className="wow fadeInUp" data-wow-delay=".6s">Transforming the maritime industry with innovative solutions for efficient vessel management and port operations.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    {/* Repeatable service items with meaningful icons and alt text where possible */}
                                    <div className="col-lg-4 col-md-6">
                                        <div className="service-box box-style">
                                            <div className="service-icon box-icon-style">
                                                <i className="lni lni-leaf"></i>
                                            </div>
                                            <div className="box-content-style service-content">
                                                <h4>Efficiency</h4>
                                                <p>Replace outdated methods with our streamlined vessel management platform.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="service-box box-style">
                                            <div className="service-icon box-icon-style">
                                                <i className="lni lni-bootstrap"></i>
                                            </div>
                                            <div className="box-content-style service-content">
                                                <h4>Access</h4>
                                                <p>Integrate real-time maritime data for effective scheduling and berth management.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="service-box box-style">
                                            <div className="service-icon box-icon-style">
                                                <i className="lni lni-briefcase"></i>
                                            </div>
                                            <div className="box-content-style service-content">
                                                <h4>Intelligence</h4>
                                                <p>Leverage AI-driven analytics to optimize resource allocation and minimize delays.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="service-box box-style">
                                            <div className="service-icon box-icon-style">
                                                <i className="lni lni-bolt"></i>
                                            </div>
                                            <div className="box-content-style service-content">
                                                <h4>Management</h4>
                                                <p>Enhance visibility to stabilize berth assignments and improve turnaround times.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="service-box box-style">
                                            <div className="service-icon box-icon-style">
                                                <i className="lni lni-infinite"></i>
                                            </div>
                                            <div className="box-content-style service-content">
                                                <h4>Collaboration</h4>
                                                <p>Keep stakeholders informed with real-time updates and alerts.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="service-box box-style">
                                            <div className="service-icon box-icon-style">
                                                <i className="lni lni-reload"></i>
                                            </div>
                                            <div className="box-content-style service-content">
                                                <h4>Insights</h4>
                                                <p>Monitor key metrics with dashboards to identify bottlenecks and improve operations.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Portfolio placeholder */}
                        <section id="portfolio" className="portfolio-section pt-130" aria-label="Our Portfolio">
                            {/* Add portfolio content or remove this section if not needed */}
                            <section id="team" className="contact-section cta-bg img-bg pt-110 pb-100" style={{ backgroundImage: "url('assets/img/bg/cta-bg.jpg')" }} aria-label="Experience OceanOps">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-xl-5 col-lg-7">
                                            <div className="section-title mb-60">
                                                <h2 className="text-white wow fadeInUp" data-wow-delay=".4s">
                                                    Experience the Power of OceanOps
                                                </h2>
                                                <p className="text-white wow fadeInUp" data-wow-delay=".6s">
                                                    Discover the full OceanOps platform for complete port and vessel management. Contact us to get started.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-xl-7 col-lg-5">
                                            <div className="contact-btn text-left text-lg-right">
                                                <a href="https://rebrand.ly/fancy-ud" rel="nofollow" className="theme-btn">Purchase Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>

                        {/* Process Section */}
                        <section id="process" className="process-section pt-100 pb-100" aria-label="Working Process">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-7 col-md-9 mx-auto">
                                        <div className="section-title text-center mb-55">
                                            <span className="wow fadeInDown" data-wow-delay=".2s">Process</span>
                                            <h2 className="wow fadeInUp" data-wow-delay=".4s">Working Process</h2>
                                            <p className="wow fadeInUp" data-wow-delay=".6s">A Seamless Journey from Concept to Implementation</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center time-line">
                                    <div className="col-12">
                                        <div className="single-timeline">
                                            <div className="row align-items-center">
                                                <div className="col-lg-5 order-last order-lg-first">
                                                    <div className="timeline-content left-content text-lg-right">
                                                        <div className="box-icon-style">
                                                            <i className="lni lni-search-alt"></i>
                                                        </div>
                                                        <h4 className="mb-10">Assess</h4>
                                                        <p>Evaluate relevant data to understand your operational needs and challenges.</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2"></div>
                                                <div className="col-lg-5">
                                                    <div className="timeline-img">
                                                        <img src="assets/img/timeline/timeline-1.png" alt="Assess phase illustration" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="single-timeline">
                                            <div className="row align-items-center">
                                                <div className="col-lg-5">
                                                    <div className="timeline-img">
                                                        <img src="assets/img/timeline/timeline-2.png" alt="Pilot phase illustration" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2"></div>
                                                <div className="col-lg-5">
                                                    <div className="timeline-content right-content text-left">
                                                        <div className="box-icon-style">
                                                            <i className="lni lni-layers"></i>
                                                        </div>
                                                        <h4 className="mb-10">Pilot</h4>
                                                        <p>Develop a proof of concept (POC) to demonstrate the effectiveness of our solution.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="single-timeline">
                                            <div className="row align-items-center">
                                                <div className="col-lg-5 order-last order-lg-first">
                                                    <div className="timeline-content left-content text-lg-right">
                                                        <div className="box-icon-style">
                                                            <i className="lni lni-code-alt"></i>
                                                        </div>
                                                        <h4 className="mb-10">Implement</h4>
                                                        <p>Integrate the solution into your systems and train your team for effective use.</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2"></div>
                                                <div className="col-lg-5">
                                                    <div className="timeline-img">
                                                        <img src="assets/img/timeline/timeline-3.png" alt="Implement phase illustration" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="single-timeline">
                                            <div className="row align-items-center">
                                                <div className="col-lg-5">
                                                    <div className="timeline-img">
                                                        <img src="assets/img/timeline/timeline-4.png" alt="Support phase illustration" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2"></div>
                                                <div className="col-lg-5">
                                                    <div className="timeline-content right-content text-left">
                                                        <div className="box-icon-style">
                                                            <i className="lni lni-rocket"></i>
                                                        </div>
                                                        <h4 className="mb-10">Support</h4>
                                                        <p>Provide ongoing support and maintenance to ensure optimal performance.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Contact Section */}
                        <section id="contact" className="contact-section pt-130 pb-100" aria-label="Contact OceanOps">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-4">
                                        <div className="contact-item-wrapper">
                                            <div className="row">
                                                <div className="col-12 col-md-6 col-xl-12">
                                                    <div className="contact-item">
                                                        <div className="contact-icon">
                                                            <i className="lni lni-phone"></i>
                                                        </div>
                                                        <div className="contact-content">
                                                            <h4>Contact</h4>
                                                            <p>91-7548082621</p>
                                                            <p>ind.baman@gmail.com</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-xl-12">
                                                    <div className="contact-item">
                                                        <div className="contact-icon">
                                                            <i className="lni lni-map-marker"></i>
                                                        </div>
                                                        <div className="contact-content">
                                                            <h4>Address</h4>
                                                            <p>400001, Mumbai</p>
                                                            <p>India</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-xl-12">
                                                    <div className="contact-item">
                                                        <div className="contact-icon">
                                                            <i className="lni lni-alarm-clock"></i>
                                                        </div>
                                                        <div className="contact-content">
                                                            <h4>Schedule</h4>
                                                            <p>24 Hours / 7 Days Open</p>
                                                            <p>Office time: 10 AM - 5:30 PM</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-8">
                                        <div className="contact-form-wrapper">
                                            <div className="row">
                                                <div className="col-xl-10 col-lg-8 mx-auto">
                                                    <div className="section-title text-center mb-50">
                                                        <span className="wow fadeInDown" data-wow-delay=".2s">Get in Touch</span>
                                                        <h2 className="wow fadeInUp" data-wow-delay=".4s">Ready to Get Started?</h2>
                                                        <p className="wow fadeInUp" data-wow-delay=".6s">We look forward to collaborating with you to elevate your maritime operations and achieve lasting success together.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <form action="assets/php/mail.php" className="contact-form" aria-label="Contact Form">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <input type="text" name="name" id="name" placeholder="Name" required aria-required="true" aria-label="Name" />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input type="email" name="email" id="email" placeholder="Email" required aria-required="true" aria-label="Email" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <input type="text" name="phone" id="phone" placeholder="Phone" required aria-required="true" aria-label="Phone" />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <input type="text" name="subject" id="subject" placeholder="Subject" required aria-required="true" aria-label="Subject" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <textarea name="message" id="message" placeholder="Type Message" rows="5" aria-label="Your Message"></textarea>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <button type="submit" className="theme-btn">Send Message</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* Subscribe Section */}
                        <section className="subscribe-section pt-70 pb-70 img-bg" style={{ backgroundImage: "url('assets/img/bg/common-bg.svg')" }} aria-label="Subscribe to Our Newsletter">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="section-title mb-30">
                                            <span className="text-white wow fadeInDown" data-wow-delay=".2s">Subscribe</span>
                                            <h2 className="text-white mb-40 wow fadeInUp" data-wow-delay=".4s">Subscribe Our Newsletter</h2>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6">
                                        <form action="#" className="subscribe-form wow fadeInRight" data-wow-delay=".4s" aria-label="Newsletter Subscription Form">
                                            <input type="email" name="subs-email" id="subs-email" placeholder="Your Email" aria-label="Your Email for subscription" />
                                            <button type="submit" aria-label="Subscribe"><i className="lni lni-telegram-original"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>

                    {/* Footer */}
                    <footer className="pt-50 pb-50 text-center" aria-label="Site Footer">
                        <div className="container">
                            <p>&copy; {new Date().getFullYear()} OceanOps AI. All Rights Reserved.</p>
                            <p>
                                <a href="https://www.linkedin.com/company/oceanopsai" target="_blank" rel="noopener noreferrer" aria-label="Visit our LinkedIn Page">Follow us on LinkedIn</a>
                            </p>
                        </div>
                    </footer>

                    <a href="#" className="scroll-top" aria-label="Scroll to Top">
                        <i className="lni lni-arrow-up"></i>
                    </a>
                </>
            )}
        </div>
    );
};

export default LandingPage;
